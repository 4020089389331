window.zoneData = [
    {
        id: 'z-001',
        name: 'Commercial & Logistics',
        color: 'zone-1-color',
        colorHEX: 'rgba(180, 211, 233, 0.5)',
        company: [{
            id: "z-001-c-0001",
            name: "Beijing Automotive International Corporation",
            logo: "baic-logo.png",
            description: " <strong>Beijing Automotive International Corporation (BAIC)</strong> is a Chinese Completely Knocked Down (CKD) Kit vehicle manufacturer and producer of passenger vehicles, SUV’s, pick-up trucks and commercial vehicles.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Baic.jpg&title=BAIC%20SA&author=Coega&preview=examples/panoramic/preview/baic.png",
            video: "",
            coordinates: [25.65344, -33.815553],
            sector: "Automotive",
            country: "China",
            flag: "China.png",
            investment: "R11",
            base: "billion",
            products: "Passenger Vehicles, SUV’s, Pick-Up Trucks"
        }, {
            id: "z-001-c-0002",
            name: "Isuzu SA",
            logo: "isuzu-logo.png",
            description: "<strong>Isuzu Motors South Africa</strong> is a wholly-owned subsidiary of Isuzu Motors Limited of Japan. Manufactures, market and distributes Isuzu light, medium and heavy commercial vehicles and recently added a sport utility vehicle, the MU-X, to its portfolio. Isuzu products are sold through a network of 80 dealers across South Africa and 35 dealers in Sub-Saharan Africa. <br/><strong>Website</strong> <a href=https://www.isuzu.co.za>https://www.isuzu.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/isuzu.jpg&title=Isuzu%20SA&author=Coega&preview=examples/panoramic/preview/isuzu.png",
            video: "",
            coordinates: [25.660796, -33.813908],
            sector: "Automotive",
            country: "Japan",
            flag: "Japan.png",
            investment: "R15",
            base: "million",
            products: "Parts Distribution Centre"
        }, {
            id: "z-001-c-0003",
            name: "Vector Logistics",
            logo: "vectorlogistics-logo.png",
            description: "<strong>Vector Logistics</strong> is a specialist frozen third-party logistics service provider (3PL) for the food industry in Southern Africa, providing integrated logistics services to the retail, wholesale and food service sectors. Its products include bulk cold storage and primary refrigerated transport. <br/><strong>Website:</strong> <a href=https://www.vectorlog.com>https://www.vectorlog.com</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/vectorlogistics.jpg&title=Vector%20Logistics&author=Coega&preview=examples/panoramic/preview/vectorlogistics.png",
            video: "",
            coordinates: [25.655091, -33.808194],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R150",
            base: "million",
            products: "Cold Storage and Distribution"
        }, {
            id: "z-001-c-0004",
            name: "National Ship Chandlers South Africa",
            logo: "nationalshipChandlers-logo.png",
            description: "<strong>National Ship Chandlers South Africa</strong> offers an exclusive range of specialised services as Africa’s leading maritime link – supplying ships with everything from provisions to technical, deck, engine, cabin and bonded stores. <br/>The company works collectively to provide a holistic and cohesive service through its specialised divisions – Cruise Liner, Logistic Supply, Clearing & Forwarding and Imports & Exports – managing individual clients’ specific needs optimally, while also handling deliveries to all South African Ports and off port limits. <br/><strong>Website:</strong> <a href=https://www.natship.net>https://www.natship.net</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/nationalshipChandlers.jpg&title=National%20Ship%20Chandlers&author=Coega&preview=examples/panoramic/preview/nationalshipChandlers.png",
            video: "",
            coordinates: [25.66234, -33.801184],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R1.2",
            base: "million",
            products: "Ship Supplier, Logistic Supply, Clearing & Forwarding and Imports & Exports"
        }, {
            id: "z-001-c-0005",
            name: "Famous Brands",
            logo: "famousbrands-logo.png",
            description: "<strong>Famous Brands Limited</strong> is a holding company listed on the JSE Limited under the category Consumer Services: Travel and Leisure and is Africa’s largest branded food service franchisor. <br/><strong>Website:</strong> <a href=https://famousbrands.co.za>https://famousbrands.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/famousbrands.jpg&title=Famous%20Brands&author=Coega&preview=examples/panoramic/preview/famousbrands.png",
            video: "",
            coordinates: [25.655255, -33.806553],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R20",
            base: "million",
            products: "Food Storage and Logistics"
        }, {
            id: "z-001-c-0006",
            name: "Digistics",
            logo: "digistics-logo.png",
            description: "<strong>Digistics</strong>  is strategically placed in the Quick Service Restaurant (QSR) industry and provides an array of key service delivery options to all its customers. <br/><strong>Website</strong> <a href=http://www.digistics.co.za>http://www.digistics.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/digistics.jpg&title=Digistics&author=Coega&preview=examples/panoramic/preview/digistics.png",
            video: "",
            coordinates: [25.66142, -33.801767],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R20",
            base: "million",
            products: "Fast Food Warehousing for Distribution to KFC’s"
        }, {
            id: "z-001-c-0007",
            name: "Lactalis SA",
            logo: "lactalis-2-logo.png",
            description: "<strong>Lactalis South Africa</strong> is the new name for Parmalat SA, one of the major \n   players in the South African dairy industry and has been active since 1998. \n     The brand focuses on quality and the annual array of national and international \n     awards our products receive are testament to this. Lactalis SA forms part of  \n               Lactalis, a French international dairy company.<br/><strong>Website:</strong><a href=https://lactalis.co.za>https://lactalis.co.za</a>",
            video: "",
            coordinates: [25.656473, -33.803065],
            sector: "Agro-Processing",
            country: "France",
            flag: "France.png",
            investment: "R3.3",
            base: "million",
            products: "Dairy Products"
        }, {
            id: "z-001-c-0008",
            name: "PE Cold Storage",
            logo: "pecoldstorage-logo.png",
            description: "<strong>PE Cold Storage</strong> operates a pre-cooling and storage facility for the local citrus and deciduous fruit industries. <br/> <strong>Website:</strong> No website",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/pecoldstorage.jpg&title=PE%20Cold%20Storage&author=Coega&preview=examples/panoramic/preview/pecoldstorage.png",
            video: "",
            coordinates: [25.656659, -33.811071],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R50",
            base: "million",
            products: "Cold Storage, Export Facilities"
        }, {
            id: "z-001-c-0009",
            name: "APM Terminals",
            logo: "apmterminals-logo.png",
            description: "<strong>APM Terminals</strong> operates a Global Terminal Network of over 23 000 employees on five continents which includes 62 operating port and terminal facilities in 40 countries and over 170 Inland Services operations in 48 countries, providing port management and operations to over 60 liner shipping customers who serve the world’s leading importers and exporters of containerised and other cargoes. <br/><strong>Website:</strong> <a href=https://www.apmterminals.com>https://www.apmterminals.com</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/apmterminals.jpg&title=APM%20Terminals&author=Coega&preview=examples/panoramic/preview/apmterminals.png",
            video: "",
            coordinates: [25.657238, -33.808738],
            sector: "Logistics",
            country: "Denmark",
            flag: "Denmark.png",
            investment: "R50",
            base: "million",
            products: "Container Terminals"
        }, {
            id: "z-001-c-0010",
            name: "Dynafoam Convertors",
            logo: "dynafoam-converters-logo.png",
            description: "<strong>Dynafoam Convertors</strong> Established and commissioned early 2011 and centrally based in the Eastern Cape Automotive hub, DynaFoam Converters is committed to service all sectors of Industry with high quality foam and rubber components sourced worldwide to meet client’s expectations.<br/><strong>Website:</strong> <a href=https://dynafoam.co.za>https://dynafoam.co.za</a>",
            video: "",
            coordinates: [25.6520631, -33.8010871],
            sector: "Automotive",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R32",
            base: "million",
            products: "Pressed Foam, Rubber and Felt components"

        }, {
            id: "z-001-c-0011",
            name: "The Courier Guy",
            logo: "the-courier-guy-logo.png",
            description: "<strong>The Courier Guy</strong>  was established in 2000 as a local same-day delivery service. It is now a well established franchise and contractor based courier company that offers a wide range of local, national and international courier and distribution services, and is situated in Zone 1 of the Coega SEZ.<br/><strong>Website:</strong> <a href=https://www.thecourierguy.co.za>https://www.thecourierguy.co.za</a>",
            video: "",
            coordinates: [25.6554149, -33.804793],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R1",
            base: "million",
            products: "Courier Services"
        }],
        center: [25.661584, -33.803612],
        coordinates: [[
            [25.6651997, -33.784162],
            [25.6646203, -33.7854282],
            [25.664041, -33.7865161],
            [25.6630539, -33.7880498],
            [25.6616377, -33.7899044],
            [25.6603932, -33.7921157],
            [25.6602215, -33.7931322],
            [25.6608223, -33.7957713],
            [25.6613802, -33.7972514],
            [25.6626677, -33.7980894],
            [25.6621742, -33.7986957],
            [25.6612944, -33.7981429],
            [25.6604361, -33.7978576],
            [25.6590628, -33.7979111],
            [25.6581401, -33.7982856],
            [25.6572603, -33.7991593],
            [25.6562733, -33.801299],
            [25.6529903, -33.8093938],
            [25.6482052, -33.8213384],
            [25.6479048, -33.8233706],
            [25.6489133, -33.8236915],
            [25.6497072, -33.8237271],
            [25.6509947, -33.8231389],
            [25.6544065, -33.8190744],
            [25.6597065, -33.8223545],
            [25.6688689, -33.8065768],
            [25.6685042, -33.806238],
            [25.6694698, -33.8050077],
            [25.6664871, -33.8030999],
            [25.668354, -33.8011029],
            [25.669105, -33.8015843],
            [25.6703495, -33.8022797],
            [25.6714868, -33.8019588],
            [25.6755852, -33.7947906],
            [25.6666373, -33.7844473],
            [25.6651997, -33.784162]
        ]]
    },
    {
        id: 'z-002',
        name: 'Automotive',
        color: 'zone-2-color',
        colorHEX: 'rgba(10, 108, 67, 0.5)',
        company: [{
            id: "z-002-c-0001",
            name: "ZacPak",
            logo: "zacpak-logo.png",
            description: "<strong>ZacPak</strong> provides goods and services in the freight movement industry. The company offers warehousing, transport and services such as storage, import and  exports services like container storage and packing, short-term and long-term storage as well as state of the art container freight stations specialising in loading and unpacking of international shipping containers. <br/><strong>Website:</strong> <a href=https://sacocfr.co.za/services>https://sacocfr.co.za/services</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/zacpak.jpg&title=ZacPak&author=Coega&preview=examples/panoramic/preview/zacpak.png",
            video: "",
            coordinates: [25.646548, -33.820624],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R4.5",
            base: "million",
            products: "Import and Export Services, Warehousing"
        }, {
            id: "z-002-c-0002",
            name: "FAW",
            logo: "faw-logo.png",
            description: "<strong>FAW-SA</strong> is a subsidiary of China FAW Group Co., Ltd., China’s oldest and largest passenger car, bus, coach and commercial truck manufacturer, with new and cutting-edge vehicles that meet and exceed the demands of their customers around the world. <br/><strong>Website:</strong> <a href=https://www.faw.co.za>https://www.faw.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/faw.jpg&title=FAW&author=Coega&preview=examples/panoramic/preview/faw.png",
            video: "/timelapse/FAW.mp4",
            coordinates: [25.648366, -33.816248],
            sector: "Automotive",
            country: "China",
            flag: "China.png",
            investment: "R600",
            base: "million",
            products: "Assemble of Trucks and Passenger Cars"
        }, {
            id: "z-002-c-0003",
            name: "HELLA Automotive South Africa",
            logo: "hella-logo.png",
            description: "<strong>HELLA Automotive South Africa (HASA)</strong> situated at Nelson Mandela Bay Logistics Park delivers a comprehensive range of products together with high levels of  service and support in its sub-Sahara domain. HELLA offers products and services ranging from automotive lighting and electronics, tools and specialised equipment for vehicles, caravans and trucks. <br/><strong>Website:</strong> <a href=https://www.hella.com/techworld/za>https://www.hella.com/techworld/za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Hella.jpg&title=HELLA%20Automotive%20South%20Africa&author=Coega&preview=examples/panoramic/preview/hella.png",
            video: "",
            coordinates: [25.646884, -33.819758],
            sector: "Automotive/OE/IAM",
            country: "Germany/ South Africa",
            flag: "South_Africa_Germany.png",
            investment: "R53",
            base: "million",
            products: "Automotive Tools and Services"
        }],
        center: [25.645688, -33.811169],
        coordinates: [[
            [25.6546493, -33.7945066],
            [25.6474953, -33.8009763],
            [25.6393628, -33.8085719],
            [25.6319946, -33.8151452],
            [25.632295, -33.8155196],
            [25.6331506, -33.8166472],
            [25.6335663, -33.817133],
            [25.6343147, -33.8180132],
            [25.635004, -33.8187976],
            [25.635645, -33.8193614],
            [25.6363183, -33.8198249],
            [25.6372329, -33.8202572],
            [25.6379544, -33.8204822],
            [25.6384795, -33.820576],
            [25.6386404, -33.8206072],
            [25.638635, -33.8206785],
            [25.6429587, -33.8218729],
            [25.6435757, -33.8203131],
            [25.6456517, -33.8210707],
            [25.6452654, -33.8219754],
            [25.6465315, -33.822332],
            [25.6473951, -33.8210173],
            [25.6519763, -33.8096431],
            [25.6544118, -33.8036212],
            [25.6554149, -33.8011026],
            [25.6561284, -33.7987177],
            [25.6561177, -33.7982675],
            [25.6560211, -33.797786],
            [25.6551574, -33.7957443],
            [25.6546493, -33.7945066]
        ]]
    },
    {
        id: 'z-003',
        name: 'General Industries',
        color: 'zone-3-color',
        colorHEX: 'rgba(129, 130, 133, 0.5)',
        company: [{
            id: "z-003-c-0001",
            name: "Dynamic Commodities",
            logo: "dynamiccommodoties-logo.png",
            description: "<strong>Dynamic Commodities</strong> was founded in 1996 as a \n                processor of frozen fruits. The company buys \n                pineapples, apples, oranges, lemons and coconuts. \n                Employees remove the fruit pulp from the shells by \n                hand and fill each fruit shell with fruit sorbet made \n                in its ice cream plant. <br/><strong>Website:</strong> <a href=https://www.dynamicfood.com>https://www.dynamicfood.com</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Dynamic%20Commodities.jpg&title=Dynamic%20Commodities&author=Coega&preview=examples/panoramic/preview/dynamiccommodoties.png",
            video: "",
            coordinates: [25.628702, -33.792853],
            sector: "Agro-processing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R50",
            base: "million",
            products: "Fruit Processing for Export"
        }, {
            id: "z-003-c-0002",
            name: "Air Products South Africa",
            logo: "airproducts-logo.png",
            description: "<strong>Air Products South Africa (Pty) Ltd</strong> manufactures, supplies and distributes a wide variety of industrial and speciality gas products to the Southern African region. Air Products South Africa is a subsidiary of Air Products and Chemicals Incorporated (APCI), a global organisation.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Air%20Products.jpg&title=Air%20Products&author=Coega&preview=examples/panoramic/preview/airproducts.png",
            video: "",
            coordinates: [25.62655, -33.793387],
            sector: "Chemicals",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R300",
            base: "million",
            products: "Production of Industrial Gases"
        }, {
            id: "z-003-c-0003",
            name: "African Oxygen Limited",
            logo: "afrox-logo.png",
            description: "<strong>African Oxygen Limited (AFROX)</strong> is Southern Africa’s market leader in industrial gases, Liquefied Petroleum Gas (LPG) and welding products. The  company has operations in South Africa and seven other African countries, with an associate company in Mauritius and manage various operations in five other African countries  on behalf of The Linde Group (Linde) – their global gases, engineering and technology parent company. <br/><strong>Website:</strong> <a href=https://www.afrox.co.za/en/index.html>https://www.afrox.co.za/en/index.html</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Afrox.jpg&title=AFROX&author=Coega&preview=examples/panoramic/preview/afrox.png",
            video: "",
            coordinates: [25.627655, -33.795315],
            sector: "Chemicals",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R300",
            base: "million",
            products: "Development of Air Separation Unit"
        }, {
            id: "z-003-c-0004",
            name: "Dairy Group",
            logo: "dairy-group-logo.png",
            description: "<strong>Dairy Group</strong> their green technology ensures the  \n               production of dairy products that are of  \n              exceptional quality. The organisation’s advanced \n               “One Step” technology allows for substantial \n                savings in terms of energy and water consumption \n                 and improved production efficiencies with less \n                impact on the environment. <br/><strong>Website:</strong> <a href=https://www.dairygroup.co.za>https://www.dairygroup.co.za</a>",
            video: " ",
            coordinates: [25.627481, -33.78981],
            sector: "Agro-processing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R129",
            base: "million",
            products: "Dairy Processing Plant (Milk and Cheese)"
        }, {
            id: "z-003-c-0005",
            name: "Corro Master",
            logo: "corromaster-logo.png",
            description: "<strong>Corro Master</strong> is a low-cost, independent \n                manufacturer of Corrugated Cardboard Packaging \n                mainly operating in the Eastern Cape. Being an \n                independent company, it always must stay one \n                step ahead of its opposition (larger corporate \n                companies) and remains the lowest-cost producer \n                in the sector in which it finds itself. <br/><strong>Website:</strong> <a href=https://corromaster.co.za>https://corromaster.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Corro%20Master.jpg&title=Corro%20Master&author=Coega&preview=examples/panoramic/preview/corromaster.png",
            video: "",
            coordinates: [25.63348, -33.78504],
            sector: "Light Manufacturing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R90",
            base: "million",
            products: "Manufacturing of Corrugated Packaging"
        }, {
            id: "z-003-c-0007",
            name: "Coega Dairy",
            logo: "coegadairy-logo.png",
            description: "<strong>Coega Dairy (Pty) Ltd</strong>  is a state-of-the-art Tomato Paste manufacturing plant that can process 1 000 tons of raw tomatoes per day. The  tomato paste produced meets international standards and is manufactured for the South African food services market.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Coega%20Concentrate.jpg&title=Coega%20Dairy&author=Coega&preview=examples/panoramic/preview/coegaconcentrate.png",
            video: "",
            coordinates: [25.626732, -33.79104],
            sector: "Agro-processing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R10",
            base: "million",
            products: "Tomato Paste Processing"
        }, /*{
            id: "z-003-c-0008",
            name: "Ambasaam",
            logo: "ambasaam-logo.png",
            description: "<strong>Ambasaam</strong> specialises in servicing the pharmaceutical and health care industry. They offer services such as warehousing, distribution  and stock management. Their warehousing services include permanent, long and short-term warehousing, receiving of goods (emptying of  containers, unloading trucks, adding onto WH IT System and storing).",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/himoinsa.jpg&title=Ambasaam&author=Coega&preview=examples/panoramic/preview/himoinsa.png",
            video: "",
            coordinates: [25.631901, -33.788625],
            sector: "Pharmaceutical",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R5",
            base: "million",
            products: "Heath Services"
        },*/ {
            id: "z-003-c-0009",
            name: "OLME ",
            logo: "olme-logo.png",
            description: "<strong>Ocean Legacy Marine Engineering (OLM Engineering)</strong> specialises in the management and execution of turnkey projects involving the  Mechanical Installation of Stainless Steel and Carbon Steel Piping Systems, for the Pharmaceutical, Food & Beverage, Water Treatment and Automotive Industry sectors. <br/><strong>Website:</strong> <a href=https://olme.co.za>https://olme.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/olme.jpg&title=OLME&author=Coega&preview=examples/panoramic/preview/olme.png",
            video: "",
            coordinates: [25.63235, -33.788158],
            sector: "General Manufacturing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R1",
            base: "million",
            products: "Specialised Stainless-Steel Fabrication"
        }, {
            id: "z-003-c-0011",
            name: "Himoinsa",
            logo: "himoinsa-logo.png",
            description: "<strong>Himoinsa</strong> is an international business focusing on \n                design, manufacturing and distributing electricity \n                generation equipment. It has been operating in \n                Africa since 1990 and opened its office officially at the Coega SEZ in 2017. <br/><strong>Website:</strong> <a href=https://www.himoinsa.co.za>https://www.himoinsa.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/himoinsa.jpg&title=Himoinsa&author=Coega&preview=examples/panoramic/preview/himoinsa.png",
            video: "",
            coordinates: [25.632896, -33.788736],
            sector: "Energy",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R57",
            base: "million",
            products: "Warehousing and Servicing of Generators"
        }, /* {
            id: "z-003-c-0013",
            name: "MM Engineering",
            logo: "mmengineering-logo.png",
            description: "<strong>MM Engineering </strong> has a 7 000 m<sup>2</sup> warehouse for \n                manufacturing of gas cylinders, consisting of a \n                warehouse structure and ancillary buildings. The \n                warehouse was constructed using RC concrete \n                columns and clad beams with superstructure \n                brickwork and covered with aluminium sheeting.",
            panoramic: "",
            video: "",
            coordinates: [25.63328, -33.782815],
            sector: "Steel",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R350",
            base: "million",
            products: "Gas Cylinder"
        } */{
            id: "z-003-c-0014",
            name: "Mzansi Commercial Solutions",
            logo: "mzantsics-food-logo.png",
            description: "<strong>Mzansi Commercial Solutions (MCS)</strong> is a Port Elizabeth based contract packing business, offering packing solutions \n for a variety of foods in a customised selection of packaging formats, and is 100% black female owned. MzantsiCS \n  Food Bottling Labelling and Packaging, a division of Mzantsi Commercial Solutions (Pty) Ltd, is a reasonably priced, \n  environmentally sensitive, convenient service provider, offering packing services to food manufacturers and/or \n  brand owners thus taking away the need to invest in packing equipment, human resources and facilities. <br/><strong>Website:</strong> <a href=http://mzantsics.co.za>http://mzantsics.co.za</a>",
            video: "",
            coordinates: [25.632896, -33.788736],
            sector: "Logistics",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R1.3",
            base: "million",
            products: "Bottling and Labelling"
        }],
        center: [25.629627, -33.787679],
        coordinates: [[
            [25.6220344, -33.7994072],
            [25.6227388, -33.8005762],
            [25.6278294, -33.7959799],
            [25.6352323, -33.7892302],
            [25.6400629, -33.7848097],
            [25.6361737, -33.7823263],
            [25.6339663, -33.780924],
            [25.630322, -33.7785994],
            [25.6283264, -33.7781491],
            [25.6279134, -33.7783453],
            [25.6271784, -33.7804543],
            [25.6251292, -33.7864913],
            [25.6239169, -33.7911368],
            [25.6225114, -33.7973692],
            [25.6220344, -33.7994072]
        ]]
    },
    {
        id: 'z-004',
        name: 'Training & Academic',
        color: 'zone-4-color',
        colorHEX: 'rgba(84, 138, 167, 0.5)',
        company: [{
            id: "z-004-c-0001",
            name: "Discovery",
            logo: "discovery-logo.png",
            description: "<strong>Discovery</strong> is one of the largest companies in SA and \n                offers a range of products, e.g. life and insurance \n                policies. The company has developed and is \n                operating a call centre in the BPO section of the Coega SEZ. <br/><strong>Website:</strong> <a href=http://www.discovery.co.za>http://www.discovery.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/discovery.jpg&title=Discovery&author=Coega&preview=examples/panoramic/preview/discovery.png",
            video: "/timelapse/Discovery.mp4",
            coordinates: [25.618278, -33.789306],
            sector: "Business Processing Outsourcing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R15",
            base: "million",
            products: "Call Centre"
        }, {
            id: "z-004-c-0002",
            name: "WNS",
            logo: "wns-logo.png",
            description: "<strong>WNS (Holdings) Limited</strong> (NYSE: WNS) is a global \n                Business Process Management (BPM) leader. The \n                company offers business value to 200+ clients \n                around the world by combining operational \n                excellence with deep domain expertise in key \n                industry verticals, including banking and financial \n                services, healthcare, insurance, manufacturing, \n                media and entertainment, consulting and professional services. <br/><strong>Website:</strong> <a href=https://www.wns.co.za>https://www.wns.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/wns.jpg&title=WNS&author=Coega&preview=examples/panoramic/preview/wns.png",
            video: "",
            coordinates: [25.618553, -33.788564],
            sector: "Business Processing Outsourcing",
            country: "India",
            flag: "India.png",
            investment: "R33.2",
            base: "million",
            products: "Call Centre"
        }],
        center: [25.620698, -33.780259],
        coordinates: [[
            [25.6214317, -33.798281],
            [25.6246692, -33.7852676],
            [25.6258065, -33.7818969],
            [25.6275821, -33.7769476],
            [25.6208775, -33.7726453],
            [25.6187639, -33.7749372],
            [25.6120585, -33.782296],
            [25.6138894, -33.7854582],
            [25.615327, -33.7879059],
            [25.6165447, -33.7899612],
            [25.6180736, -33.7925826],
            [25.6188246, -33.7937997],
            [25.6188675, -33.7937863],
            [25.6204232, -33.7964611],
            [25.6203535, -33.7964968],
            [25.6210348, -33.7976915],
            [25.6214317, -33.798281]
        ]]
    },
    {
        id: 'z-005',
        name: 'Metallurgical',
        color: 'zone-5-color',
        colorHEX: 'rgba(167, 169, 172, 0.5)',
        company: [{
            id: "z-005-c-0001",
            name: "Bosun Bricks",
            logo: "bosunbricks-logo.png",
            description: "<strong>Bosun Bricks</strong> was established in 1993 as a privately-owned company, manufacturing and supplying\n                SANS approved kerbs, a comprehensive range of paving blocks, the Robust Block and Versoblock®\n                retaining wall system. As a recycling initiative, Bosun also manufactures limited quantities of non-ash stock bricks and hollow blocks.<br/><strong>Website:</strong> <a href=https://www.bosun.co.za>https://www.bosun.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/bosunbricks.jpg&title=Bosun%20Bricks&author=Coega&preview=examples/panoramic/preview/bosunbricks.png",
            video: "",
            coordinates: [25.62861, -33.771423],
            sector: "Construction",
            country: "Germany",
            flag: "Germany.png",
            investment: "R50",
            base: "million",
            products: "Manufacturer and\n                Distributor of Pre-Cast Products"
        }, {
            id: "z-005-c-0002",
            name: "Kenako Concrete",
            logo: "kenakoconcrete-logo.png",
            description: "<strong>Kenako Concrete (Pty) Ltd.</strong>, is a ready-mix concrete production and distribution company. <br/><strong>Website:</strong> No website",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/kenakoconcrete.jpg&title=Kenako%20Concrete&author=Coega&preview=examples/panoramic/preview/kenakoconcrete.png",
            video: "",
            coordinates: [25.627868, -33.772735],
            sector: "Construction Materials",
            country: "South Africa ",
            flag: "South_Africa.png",
            investment: "R78",
            base: "million",
            products: "Manufacturer of\n  Construction Material"
        }, {
            id: "z-005-c-0004",
            name: "Cemza",
            logo: "cemza-logo.png",
            description: "<strong>Cemza's</strong> cement grinding facility, in the Coega SEZ, \n                supplies cement to the local market. The facility is \n                being developed through a joint venture between \n                Cemza Ventures and Heidelberg Cement. \n                Heidelberg Cement is one of the world's largest \n                building materials companies with around 60,000 \n                employees, at more than 3,000 production sites in \n                approximately 60 countries on five continents. <br/><strong>Website:</strong> <a href=https://cemza.co>https://cemza.co</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/CEMZA.JPG&title=Cemza&author=Coega&preview=examples/panoramic/preview/cemza.png",
            video: "",
            coordinates: [25.641216, -33.781206],
            sector: "Construction Material",
            country: "India",
            flag: "India.png",
            investment: "R380",
            base: "million",
            products: "Cement"
        }, {
            id: "z-005-c-0003",
            name: "Sanitech",
            logo: "sanitech-logo.png",
            description: "<strong>Sanitech</strong> was founded in the early 1980’s as the \n                first portable toilet hire company in South Africa to \n                supply sanitation facilities to areas in which no \n                sanitation infrastructure existed. Today, Sanitech’s \n                Toilet Hire division has over 20 branches \n                nationwide and 8 branches in Africa (Botswana, \n                Swaziland, Zambia and Namibia) and has the \n                largest rental fleet in the country with over 25,000 units and 200 vehicles.  <br/><strong>Website:</strong> <a href=https://www.sanitech.co.za>https://www.sanitech.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/sanitech.jpg&title=Sanitech&author=Coega&preview=examples/panoramic/preview/sanitech.png",
            video: "",
            coordinates: [25.657057, -33.779605],
            sector: "Chemicals",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R10",
            base: "million",
            products: "Waste Water Treatment Plant"
        }],
        center: [25.649542, -33.775251],
        coordinates: [[
            [25.6233982, -33.7741747],
            [25.6245806, -33.7749257],
            [25.6275633, -33.7768832],
            [25.6277403, -33.7763705],
            [25.6306424, -33.7781094],
            [25.6375625, -33.7825058],
            [25.6449225, -33.7870624],
            [25.6495681, -33.7901208],
            [25.6542244, -33.7930989],
            [25.6553724, -33.7935715],
            [25.6565526, -33.7936428],
            [25.6577006, -33.7933397],
            [25.658634, -33.7926709],
            [25.6604042, -33.7902011],
            [25.6622938, -33.7874441],
            [25.6633452, -33.786062],
            [25.6644825, -33.7835295],
            [25.6650618, -33.7817907],
            [25.6654052, -33.7804976],
            [25.6654695, -33.7789281],
            [25.6650082, -33.7774745],
            [25.6633452, -33.7737825],
            [25.6628302, -33.7724536],
            [25.6620792, -33.7711248],
            [25.6599871, -33.7674057],
            [25.6585172, -33.7649084],
            [25.6579446, -33.7632552],
            [25.6570702, -33.7617077],
            [25.65611, -33.7601512],
            [25.6556091, -33.7591505],
            [25.6555126, -33.7582585],
            [25.6443918, -33.7617218],
            [25.6405026, -33.7628367],
            [25.6367851, -33.7640497],
            [25.6342101, -33.7654545],
            [25.6324184, -33.7669128],
            [25.6310022, -33.7685761],
            [25.6304282, -33.7694591],
            [25.6297952, -33.7691514],
            [25.6233982, -33.7741747]
        ]]
    },
    {
        id: 'z-006',
        name: 'Ferrous Metals',
        color: 'zone-6-color',
        colorHEX: 'rgba(17, 124, 193, 0.5)',
        company: [{
            id: "z-006-c-0001",
            name: "Coega Steel",
            logo: "agni-logo.png",
            description: "<strong>Coega Steel SA Pty (Ltd)</strong> is the first Mini Steel melting unit to be commissioned in the industrial\n                history of the Eastern Cape and is a subsidiary of Agni Steel (India), one of the leading secondary steel manufacturers in the South of India. <br/><strong>Website:</strong> <a href=https://www.agnisteels.com>https://www.agnisteels.com</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/coegasteels.jpg&title=Agni%20Steels&author=Coega&preview=examples/panoramic/preview/coegasteels.png",
            video: "",
            coordinates: [25.684287, -33.750798],
            sector: "Metals",
            country: "India",
            flag: "India.png",
            investment: "R150",
            base: "million",
            products: "Steel Manufacturer"
        }, /*{
            id: "z-006-c-0002",
            name: "Electrawinds",
            logo: "electrawinds-logo.png",
            description: "<strong>Electrawinds</strong> has invested R1.2 billion in the Coega\n                Wind Farm Project to build 25 turbines. Each wind \n                turbine has a capacity of 1.8 MW which translates \n                into an annual yield 5,700,000 kWh, enough \n                energy to power about 1,700 households. The \n                electricity generated by the wind farm is fed into \n                the national grid and distributed by Nelson \n                Mandela Bay Metropolitan to households within \n                the metro. The wind farm supplies the Nelson \n                Mandela Bay with an average of 45 MW of green energy.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/electrawinds.jpg&title=Electrawinds&author=Coega&preview=examples/panoramic/preview/electrawinds.png",
            video: "",
            coordinates: [25.676532, -33.754035],
            sector: "Energy",
            country: "Belgium",
            flag: "Belgium.png",
            investment: "R40",
            base: "million",
            products: "Wind Turbines"
        } */],
        center: [25.700400, -33.747984],
        coordinates: [[
            [25.7254762, -33.7315967],
            [25.6866427, -33.74695],
            [25.6846685, -33.7478957],
            [25.6822277, -33.7487565],
            [25.676081, -33.7503569],
            [25.673254, -33.7510617],
            [25.6723688, -33.7513248],
            [25.6715696, -33.7516326],
            [25.6706952, -33.7520608],
            [25.6695203, -33.7527387],
            [25.6688873, -33.7532427],
            [25.667675, -33.7540589],
            [25.6659906, -33.7548395],
            [25.6628529, -33.7557051],
            [25.6640626, -33.7561098],
            [25.6658372, -33.7569341],
            [25.6674827, -33.7572574],
            [25.669092, -33.7576666],
            [25.6701126, -33.7573555],
            [25.6712405, -33.7575272],
            [25.6712646, -33.7575495],
            [25.6708542, -33.7577792],
            [25.6706743, -33.7582399],
            [25.6729314, -33.7592054],
            [25.673783, -33.7604297],
            [25.6743489, -33.7609381],
            [25.6753883, -33.7618691],
            [25.6758255, -33.7624109],
            [25.6771653, -33.7653234],
            [25.6776078, -33.765979],
            [25.6776454, -33.7669311],
            [25.6887782, -33.7616271],
            [25.6909025, -33.760414],
            [25.6916965, -33.75987],
            [25.6941212, -33.757863],
            [25.6944538, -33.7577114],
            [25.6979621, -33.7569888],
            [25.6998289, -33.7563288],
            [25.7078218, -33.7527064],
            [25.7149945, -33.7493722],
            [25.7183043, -33.7478066],
            [25.721287, -33.7464238],
            [25.7255195, -33.7444076],
            [25.7306801, -33.7419543],
            [25.7369081, -33.7391082],
            [25.7254762, -33.7315967],
        ]]
    },
    {
        id: 'z-007',
        name: 'Chemicals',
        color: 'zone-7-color',
        colorHEX: 'rgba(243, 143, 39, 0.5)',
        company: [{
            id: "z-007-c-0001",
            name: "Cerebos",
            logo: "cerebos-logo.png",
            description: "<strong>Cerebos</strong> traced its name to 1892 when a French chemical engineer decided to mix calcium \n                phosphate with salt and invented dry-pouring salt. It has other companies around the globe. <br/><strong>Website:</strong> <a href=https://www.cerebos.co.za>https://www.cerebos.co.za</a>",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/cerebos.jpg&title=Cerebos&author=Coega&preview=examples/panoramic/preview/cerebos.png",
            video: "",
            coordinates: [25.698214, -33.767591],
            sector: "Chemicals",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R60",
            base: "million",
            products: "Salt Products"
        }],
        center: [25.716193, -33.759428],
        coordinates: [[
            [25.7016978, -33.7793212],
            [25.7072339, -33.7753617],
            [25.7075558, -33.7747552],
            [25.707427, -33.7742915],
            [25.7073197, -33.7738634],
            [25.7076845, -33.7737386],
            [25.7084141, -33.7738099],
            [25.7090149, -33.7740596],
            [25.7095728, -33.7738991],
            [25.7110319, -33.7733105],
            [25.7123408, -33.7724365],
            [25.720881, -33.7677988],
            [25.7312566, -33.7620696],
            [25.7375223, -33.7572173],
            [25.7390672, -33.7556474],
            [25.7403547, -33.7552549],
            [25.7423288, -33.7543272],
            [25.7439167, -33.7529713],
            [25.7465345, -33.7509018],
            [25.7483369, -33.7492247],
            [25.7496673, -33.747833],
            [25.7373506, -33.7397324],
            [25.7371393, -33.7396017],
            [25.7352403, -33.7404136],
            [25.7323006, -33.7417608],
            [25.7285455, -33.7435629],
            [25.7225803, -33.746382],
            [25.7179347, -33.7485587],
            [25.7114438, -33.7516453],
            [25.7049636, -33.7547496],
            [25.7006184, -33.756935],
            [25.6983868, -33.7582195],
            [25.6972495, -33.7590758],
            [25.696241, -33.7599231],
            [25.6955866, -33.7602442],
            [25.6943957, -33.7604137],
            [25.6925932, -33.7607616],
            [25.6913809, -33.7611719],
            [25.690147, -33.7617249],
            [25.6888488, -33.7624117],
            [25.6882266, -33.7627149],
            [25.6905851, -33.7655713],
            [25.6920014, -33.7673283],
            [25.6959603, -33.7721265],
            [25.6985674, -33.7753728],
            [25.7002411, -33.7773615],
            [25.7016978, -33.7793212]
        ]]
    },
    {
        id: 'z-008',
        name: 'Port Area',
        color: 'zone-8-color',
        colorHEX: 'rgba(69, 173, 74, 0.5)',
        company: [{
            id: "z-008-c-0001",
            name: "Port of Ngqura",
            logo: "portofngqura-logo.png",
            description: "The <strong>Port of Ngqura</strong> is a world-class deep-water container transhipment hub for sub-Saharan Africa. Handling of over 6 million tons of cargo in 2016 (over 400 vessel calls) with 30-years forecast predicting up to 110 million tons of cargo per year. Strategically positioned, this 24-hour working port in the heart of the Coega SEZ, continues to expand its service offerings to accommodate dry bulk, break-bulk, liquid bulk and project cargo. <br/><strong>Website:</strong> No website",
            panoramic: "",
            video: "/timelapse/PortOfNgqura.mp4",
            coordinates: [25.688543, -33.790863],
            sector: "Transhipment",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "",
            base: "",
            products: "Transhipment Port"
        }],
        center: [25.688499, -33.791144],
        coordinates: [[
            [25.6881573, -33.7627353],
            [25.6848349, -33.7640517],
            [25.6825604, -33.765122],
            [25.6801464, -33.7662637],
            [25.6789233, -33.7669326],
            [25.6767132, -33.7681723],
            [25.6743743, -33.7696618],
            [25.6728186, -33.7705625],
            [25.6714453, -33.771392],
            [25.6707158, -33.7719538],
            [25.670072, -33.7725157],
            [25.6695785, -33.7730062],
            [25.6686236, -33.7742637],
            [25.668173, -33.7752179],
            [25.6677975, -33.7763237],
            [25.6662362, -33.7807748],
            [25.665158, -33.7841445],
            [25.6666386, -33.7844376],
            [25.6692687, -33.787358],
            [25.6756005, -33.7947843],
            [25.6715768, -33.8019764],
            [25.670343, -33.8023509],
            [25.6683689, -33.8011919],
            [25.6665772, -33.8030908],
            [25.6695061, -33.8049986],
            [25.6685325, -33.8062311],
            [25.6690385, -33.806643],
            [25.6597065, -33.8223545],
            [25.6629231, -33.824649],
            [25.6668713, -33.8206916],
            [25.6789734, -33.809567],
            [25.6859686, -33.8106368],
            [25.6886294, -33.8103515],
            [25.6902602, -33.8095314],
            [25.6984999, -33.8018289],
            [25.6931355, -33.8183385],
            [25.6938222, -33.8186593],
            [25.6983712, -33.8040399],
            [25.6991437, -33.8015793],
            [25.6988432, -33.7995109],
            [25.6975129, -33.7971928],
            [25.7001736, -33.7935551],
            [25.7075121, -33.7884906],
            [25.7047656, -33.7849238],
            [25.705538, -33.7843888],
            [25.6881573, -33.7627353]
        ]]
    },
    {
        id: 'z-009',
        name: 'Materials Handling',
        color: 'zone-9-color',
        colorHEX: 'rgba(220, 100, 76, 0.5)',
        company: [{
            id: "z-009-c-0001",
            name: "Transnet Freight Rail",
            logo: "transnetfreightrail-logo.png",
            description: "<strong>Transnet Freight Rail</strong> is the largest division of Transnet. It is a world-class heavy haul freight rail company that specialises in the transportation of freight. The 25km of railway line includes two rail yards, of which the smaller one is in the harbour next to the container depot and quay. The railway's infrastructure upgrade introduced new passing loops to accommodate 200-wagon trains operated to use dual-voltage locomotives and support axel loads of 26 tons. <br/><strong>Website:</strong> No website",
            panoramic: "",
            video: "",
            coordinates: [25.663403, -33.769563],
            sector: "Freight Transportation",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "",
            base: "",
            products: "Freight Transportation"
        }],
        center: [25.667296, -33.764521],
        coordinates: [[
            [25.6775343, -33.7669565],
            [25.6775343, -33.7659932],
            [25.677132, -33.765351],
            [25.6765419, -33.7640266],
            [25.6758231, -33.76243],
            [25.6753886, -33.7618859],
            [25.6737739, -33.7604365],
            [25.6729263, -33.7592145],
            [25.6706612, -33.758247],
            [25.6708328, -33.7577653],
            [25.6712405, -33.7575513],
            [25.6701247, -33.7573729],
            [25.6690948, -33.7576761],
            [25.6675498, -33.7572837],
            [25.6658332, -33.7569447],
            [25.6640737, -33.7561241],
            [25.6628506, -33.7557137],
            [25.6602757, -33.7566593],
            [25.6574432, -33.7576761],
            [25.6558339, -33.7582649],
            [25.6561129, -33.759585],
            [25.6574003, -33.7616187],
            [25.6582586, -33.7630993],
            [25.6605327, -33.766293],
            [25.662657, -33.7701102],
            [25.6647169, -33.7735171],
            [25.6658971, -33.7755504],
            [25.666734, -33.7775659],
            [25.667485, -33.7753721],
            [25.6685364, -33.7735527],
            [25.6701028, -33.7718404],
            [25.6726777, -33.7700746],
            [25.6747377, -33.7687903],
            [25.6775343, -33.7669565]
        ]]
    },
    {
        id: 'z-010',
        name: 'Mariculture & Aquaculture',
        color: 'zone-10-color',
        colorHEX: 'rgba(180, 211, 233, 0.5)',
        company: [{
            id: "z-010-c-0001",
            name: "Available land",
            logo: "coega-logo.png",
            description: '<strong>Available land</strong>: 869 ha <br/>For investors who wish to take advantage of opportunities in this sector, kindly contact;<br/><br/>Dr Keith du Plessis<br/>Office: 041 403 0681<br/>Email: <a href="mailto:keith.duplessis@coega.co.za">keith.duplessis@coega.co.za</a> <br/><strong>Website:</strong> No website',
            panoramic: "",
            video: "",
            coordinates: [25.72393, -33.773879],
            pinMobileOnly: !0,
            sector: "",
            country: "",
            investment: "",
            base: "",
            products: ""
        }],
        center: [25.727245, -33.770469],
        coordinates: [[
            [25.7075121, -33.7884906],
            [25.7114838, -33.7864008],
            [25.7209466, -33.7815142],
            [25.7210539, -33.7805868],
            [25.7338856, -33.7737914],
            [25.7492708, -33.7664781],
            [25.7508372, -33.7654079],
            [25.761566, -33.7596638],
            [25.7644778, -33.7566965],
            [25.7497056, -33.7478707],
            [25.749094, -33.748571],
            [25.7466049, -33.7509305],
            [25.742367, -33.7543559],
            [25.7403876, -33.7552881],
            [25.7391055, -33.7556806],
            [25.7375659, -33.7572371],
            [25.7312949, -33.7620984],
            [25.7123638, -33.7724666],
            [25.7110549, -33.773354],
            [25.7095851, -33.7739337],
            [25.7090218, -33.7741031],
            [25.7084103, -33.7738578],
            [25.7076968, -33.773782],
            [25.7073642, -33.7739024],
            [25.7076056, -33.7747541],
            [25.7073052, -33.7753784],
            [25.7016726, -33.7794226],
            [25.7055886, -33.7843896],
            [25.7048215, -33.7849336],
            [25.7075121, -33.7884906]
        ]]
    },
    {
        id: 'z-011',
        name: 'Petrochemical',
        color: 'zone-11-color',
        colorHEX: 'rgba(26, 173, 134, 0.5)',
        company: [{
            id: "z-011-c-0001",
            name: "Electrawinds",
            logo: "electrawinds-logo.png",
            description: "<strong>Electrawinds</strong> has invested R1.2 billion in the Coega\n                Wind Farm Project to build 25 turbines. Each wind \n                turbine has a capacity of 1.8 MW which translates \n                into an annual yield 5,700,000 kWh, enough \n                energy to power about 1,700 households. The \n                electricity generated by the wind farm is fed into \n                the national grid and distributed by Nelson \n                Mandela Bay Metropolitan to households within \n                the metro. The wind farm supplies the Nelson \n                Mandela Bay with an average of 45 MW of green energy. <br/><strong>Website:</strong> No website",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/electrawinds.jpg&title=Electrawinds&author=Coega&preview=examples/panoramic/preview/electrawinds.png",
            video: "",
            coordinates: [25.676532, -33.754035],
            sector: "Energy",
            country: "Belgium",
            flag: "Belgium.png",
            investment: "R1",
            base: "billion",
            products: "Wind Turbines"
        }],
        center: [25.697363, -33.723557],
        coordinates: [[
            [25.6750496, -33.7500181],
            [25.680605, -33.7486323],
            [25.6861626, -33.7466697],
            [25.7254762, -33.7315967],
            [25.7192374, -33.7275948],
            [25.7246886, -33.721701],
            [25.6975278, -33.7043477],
            [25.6958488, -33.7042629],
            [25.6917933, -33.7016254],
            [25.6909345, -33.7009994],
            [25.6800304, -33.7095875],
            [25.6784439, -33.7116245],
            [25.6779504, -33.7129632],
            [25.6776071, -33.7145428],
            [25.6771288, -33.7158597],
            [25.6744699, -33.7213753],
            [25.675575, -33.7215984],
            [25.6813092, -33.7292474],
            [25.6815774, -33.7363854],
            [25.683162, -33.7416752],
            [25.6820445, -33.7422468],
            [25.6815832, -33.7417829],
            [25.6758996, -33.7446311],
            [25.6751352, -33.7476352],
            [25.6747248, -33.74927],
            [25.6750496, -33.7500181]
        ]]
    },
    {
        id: 'z-012',
        name: 'Advanced Manufacturing',
        color: 'zone-12-color',
        colorHEX: 'rgba(89, 158, 185, 0.5)',
        company: [{
            id: "z-012-c-0001",
            name: "Available land",
            logo: "coega-logo.png",
            description: '<strong>Available land</strong>: 837 ha<br/>For investors who wish to take advantage of opportunities in this sector, kindly contact;<br/><br/>Mr Johan Fourie<br/>Office: 041 403 0417<br/>Email: <a href="mailto:johan.fourie@coega.co.za">johan.fourie@coega.co.za</a> <br/><strong>Website:</strong> No website',
            panoramic: "",
            video: "",
            coordinates: [25.669058, -33.704638],
            pinMobileOnly: !0,
            sector: "",
            country: "",
            investment: "",
            base: "",
            products: ""
        }],
        center: [25.669058, -33.704638],
        coordinates: [[
            [25.6674202, -33.6858524],
            [25.6447268, -33.710406],
            [25.6743477, -33.721324],
            [25.6770728, -33.7157286],
            [25.6775342, -33.7143186],
            [25.6780921, -33.7121946],
            [25.678548, -33.7113824],
            [25.6796048, -33.7100615],
            [25.6799964, -33.709535],
            [25.6823031, -33.7077321],
            [25.6843416, -33.7061479],
            [25.6865732, -33.7043807],
            [25.6908908, -33.70098],
            [25.6674202, -33.6858524]
        ]]
    },
    {
        id: 'z-013',
        name: 'Energy',
        color: 'zone-13-color',
        colorHEX: 'rgba(211, 37, 43, 0.5)',
        company: [{
            id: "z-013-c-0001",
            name: "Dedisa Peaking Power Plant",
            logo: "dedisapeakingpowerplant-logo.png",
            description: "<strong>Avon and Dedisa Peaking Power Plants</strong> are South Africa’s first large privately-owned power generating plants initiated by the Department of Energy. The power generated by the two open cycle gas turbine (OCGT) power plants supplies Eskom SOC LTD (Eskom) with electricity during peak demand times. The plants are located in the Coega SEZ and the Shakaskraal, Kwazulu-Natal. <br/><strong>Website:</strong> No website",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/dedisapeakingpowerplant.jpg&title=Dedisa%20Peaking%20Power%20Plant&author=Coega&preview=examples/panoramic/preview/dedisapeakingpowerplant.png",
            video: "",
            coordinates: [25.672395, -33.742621],
            sector: "Energy",
            country: "France",
            flag: "France.png",
            investment: "R3.5",
            base: "billion",
            products: "Power Generating Plant"
        }],
        center: [25.673726, -33.739160],
        coordinates: [[
            [25.656035, -33.7578412],
            [25.6648357, -33.7546925],
            [25.667239, -33.7536577],
            [25.6699426, -33.7516239],
            [25.6710155, -33.7511243],
            [25.6750496, -33.7500181],
            [25.6746955, -33.7492777],
            [25.675865, -33.7446209],
            [25.6805642, -33.7422657],
            [25.6815942, -33.7417661],
            [25.6820448, -33.74223],
            [25.6830104, -33.7416412],
            [25.6818302, -33.7376799],
            [25.6815298, -33.7363952],
            [25.6812723, -33.7292572],
            [25.6755431, -33.7216367],
            [25.6743844, -33.7213868],
            [25.6714232, -33.7286504],
            [25.6696422, -33.7306134],
            [25.6676038, -33.7346286],
            [25.6668742, -33.738019],
            [25.6670888, -33.7407312],
            [25.6670029, -33.7436574],
            [25.6658013, -33.7458342],
            [25.6638272, -33.7473687],
            [25.6621964, -33.7482251],
            [25.6603511, -33.7512224],
            [25.6591494, -33.7528638],
            [25.6573041, -33.7542554],
            [25.6562312, -33.7560394],
            [25.655802, -33.7573239],
            [25.656035, -33.7578412]
        ]]
    },
    {
        id: 'z-014',
        name: 'Aeronautical & Aerospace',
        color: 'zone-14-color',
        colorHEX: 'rgba(90, 89, 91, 0.5)',
        company: [{
            id: "z-014-c-0001",
            name: "Available land",
            logo: "coega-logo.png",
            description: '<strong>Available land</strong>: 2240 ha<br/>For investors who wish to take advantage of opportunities in this sector, kindly contact;<br/><br/>Mr Johan Fourie<br/>Office: 041 403 0417<br/>Email: <a href="mailto:johan.fourie@coega.co.za">johan.fourie@coega.co.za</a>',
            panoramic: "",
            video: "",
            coordinates: [25.640611, -33.739827],
            pinMobileOnly: !0,
            sector: "",
            country: "",
            investment: "",
            base: "",
            products: ""
        }],
        center: [25.641396, -33.742877],
        coordinates: [[
            [25.6303637, -33.769253],
            [25.6309834, -33.7684117],
            [25.6322494, -33.7669133],
            [25.633966, -33.7654685],
            [25.6365409, -33.7640236],
            [25.6437078, -33.7618829],
            [25.6556356, -33.7579663],
            [25.655708, -33.7573286],
            [25.656216, -33.7560442],
            [25.6572792, -33.7542469],
            [25.6574133, -33.7541466],
            [25.6591272, -33.7528576],
            [25.6596449, -33.752164],
            [25.6603316, -33.7512118],
            [25.6621848, -33.7482247],
            [25.6638169, -33.7473649],
            [25.6657883, -33.7458349],
            [25.6669859, -33.7436548],
            [25.6670718, -33.7407297],
            [25.6668599, -33.7380319],
            [25.6675775, -33.7346286],
            [25.6695918, -33.7306314],
            [25.6712972, -33.7286046],
            [25.6743223, -33.7213649],
            [25.6447268, -33.710406],
            [25.6334999, -33.7226109],
            [25.6178987, -33.7318046],
            [25.6175992, -33.7317055],
            [25.6033130, -33.745762],
            [25.6256216, -33.7671482],
            [25.6303637, -33.769253]
        ]]
    },
    {
        id: 'z-nmblp-a',
        name: 'Nelson Mandela Bay Logistics Park',
        color: 'zone-14-color',
        colorHEX: 'rgba(90, 89, 91, 0.5)',
        company: [{
            id: "z-nmblp-a-0001",
            name: "Benteler",
            logo: "benteller-logo.png",
            description: 'The <strong>Benteler Group</strong> is organised into the three divisions of Benteler Automotive, Benteler Steel/Tube and Benteler Distribution. With almost 150 locations in around 40 countries, Benteler always endeavours for geographical proximity and strategic alignment with their business partners — "We support our partners from product idea to series production. We are where you need us."',
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Benteler.jpg&title=Benteler%20Group&author=Coega&preview=examples/panoramic/preview/benteller.png",
            video: "",
            coordinates: [25.422483, -33.80643],
            sector: "Automotive",
            country: "Germany",
            flag: "Germany.png",
            investment: "R173",
            base: "million",
            products: "Meta Based Products and Systems"
        }, {
            id: "z-nmblp-a-0002",
            name: "Faurecia",
            logo: "favrecia-logo.png",
            description: "<strong>Faurecia</strong> is located in the NMB Logistics Park and provides innovative solutions to automotive challenges. Faurecia works hand in hand with automakers to develop cars that are cleaner, lighter, more comfortable and easier to customise. They provide specialised vehicle parts such as seating, interiors and clean vehicle mobility systems.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Faurecia.jpg&title=Faurecia&author=Coega&preview=examples/panoramic/preview/faurecia.png",
            video: "",
            coordinates: [25.423523, -33.80209],
            sector: "Automotive",
            country: "France",
            flag: "France.png",
            investment: "R120",
            base: "million",
            products: "Vehicle Seating, Interior and Mobility Systems"
        }, {
            id: "z-nmblp-a-0003",
            name: "Mediterranean Shipping Company",
            logo: "msclogistics-logo.png",
            description: "<strong>Mediterranean Shipping Company (MSC)</strong> has an innovative, flexible and unique approach to shipping that has allowed the company to grow significantly over the last twenty years. Founded in 1970, MSC is now the biggest user of the South African ports and by 2003 was the second biggest container carrier in the world.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/MSC.jpg&title=Mediterranean%20Shipping%20Company&author=Coega&preview=examples/panoramic/preview/msclogistics.png",
            video: "",
            coordinates: [25.425934, -33.805879],
            sector: "Automotive",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R50",
            base: "million",
            products: "Shipping Containers"
        }, {
            id: "z-nmblp-a-0004",
            name: "Q-Plas",
            logo: "qplas-logo.png",
            description: "<strong>Q-Plas</strong> is an owner-managed, first-tier automotive component supplier. The company has a B-BBEE 4 rating, as well as IATF 16949 accreditation. Q-Plas focuses on injection moulding of interior and exterior surface components as well as exterior mirror and other sub-assemblies.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/ITPSA.jpg&title=Q-Plas&author=Coega&preview=examples/panoramic/preview/itpsa.png",
            video: "",
            coordinates: [25.421898, -33.804218],
            sector: "Automotive",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R165.4",
            base: "million",
            products: "Auto Components"
        }, {
            id: "z-nmblp-a-0005",
            name: "Grupo Antolin",
            logo: "antolin-logo.png",
            description: "<strong>Grupo Antolin</strong> is a multi-national leader in the conception and manufacture of interior automotive components and modular systems.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Antolin.jpg&title=Grupo%20Antolin&author=Coega&preview=examples/panoramic/preview/antolin.png",
            video: "",
            coordinates: [25.424458, -33.805251],
            sector: "Automotive",
            country: "Spain",
            flag: "Spain.png",
            investment: "R124",
            base: "million",
            products: "Headliners and Panels"
        }, {
            id: "z-nmblp-a-0007",
            name: "REHAU",
            logo: "rehau-logo.png",
            description: "<strong>REHAU</strong> is a leading system and service provider for polymer-based solutions in construction, automotive and industry. By combining strong development capabilities with decentralised sales and service excellence, they have become one of the top providers in the market.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/Rehau.jpg&title=REHAU&author=Coega&preview=examples/panoramic/preview/rehau.png",
            video: "",
            coordinates: [25.420419, -33.804353],
            sector: "Automotive",
            country: "Germany",
            flag: "Germany.png",
            investment: "R325",
            base: "million",
            products: "Auto Components Supplier to VW"
        }, {
            id: "z-nmblp-a-0008",
            name: "Industrial Técnica Pecuaria, S.A.",
            logo: "itpsa-logo.png",
            description: "<strong>Industrial Técnica Pecuaria, S.A. (ITPSA)</strong>, is a company established in 1968 and a leading manufacturer of supplements for animal feeds. All ITPSA's products are the result of ITPSA's research. They have been designed for best production results with maximum profitability while offering a technical analysis service in its laboratory, which allows the creation of new formulations and exhaustive quality control.",
            panoramic: "/panorama/index.html?panorama=examples/panoramic/ITPSA.jpg&title=Industrial%20Técnica%20Pecuaria,%20S.A&author=Coega&preview=examples/panoramic/preview/itpsa.png",
            video: "",
            coordinates: [25.423494, -33.804171],
            sector: "Agro-processing",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "R30",
            base: "million",
            products: "Cigarette Processing"
        }, {
            id: "z-nmblp-a-0009",
            name: "Motor Industry Customs Brokers",
            logo: "micb-logo.png",
            description: "<strong>Motor Industry Customs Brokers (MICB)</strong> specialises in all aspects of the Automotive Production Development Programme (APDP) including preparing and finalising the Production Incentive (PI), Forms C1, as well as the Standard Material Declaration (SMD).",
            panoramic: "",
            video: "",
            coordinates: [25.424913, -33.803243],
            sector: "Automotive",
            country: "South Africa",
            flag: "South_Africa.png",
            investment: "Value not disclosed",
            base: "",
            products: "Auto Claims"
        }, {
            id: "z-nmblp-a-0011",
            name: "Schnellecke Group",
            logo: "schnellecke-logo.png",
            description: "The <strong>Schnellecke Group</strong> is an internationally operating, family-owned company that offers a variety of logistics services. One of the major focuses of these activities is value-added logistics for the automotive industry. The company develops complete concepts that range from transport and warehousing, to pre-assemblies and value-added services, to sequential production of individual parts and modules and packaging suitable for containers. Furthermore, Schnellecke produces individual car body parts and car body modules for vehicle manufacturing under the KWD Automotive umbrella brand.",
            panoramic: "",
            video: "",
            coordinates: [25.424992, -33.803524],
            sector: "Automotive, Logistics",
            country: "Germany",
            flag: "Germany.png",
            investment: "Value not disclosed",
            base: "",
            products: "Automotive manufacturing and logistics"
        }, {
            id: "z-nmblp-b-vw",
            name: "Volkswagen Group SA",
            logo: "vw-logo.png",
            description: "Established in 1946, <strong>Volkswagen Group South Africa (VWSA)</strong> is a wholly owned subsidiary of Volkswagen Aktiengessellschaft (VWAG) in Germany. It is the largest German investment in South Africa and is a major contributor to foreign direct investment (FDI), technology transfer and skills development. VWSA is located in Uitenhage, an industrial town in the Eastern Cape and is only 3 km from the Nelson Mandela Bay Logistics Park. ",
            panoramic: "",
            video: "",
            coordinates: [25.417754, -33.785855],
            sector: "",
            country: "",
            investment: "",
            base: "million",
            products: ""
        }],
        center: [25.42317, -33.80357],
        coordinates: [[
            [25.4284191, -33.7995586],
            [25.4211664, -33.7999197],
            [25.4185378, -33.8021285],
            [25.4189509, -33.8027258],
            [25.4197716, -33.8034323],
            [25.419828, -33.8036128],
            [25.4192888, -33.8040007],
            [25.4191574, -33.8055385],
            [25.4196322, -33.8055719],
            [25.4195115, -33.8068713],
            [25.4211771, -33.8070139],
            [25.4211288, -33.8076156],
            [25.4233309, -33.807785],
            [25.4234328, -33.8067242],
            [25.4266139, -33.8069983],
            [25.4268366, -33.8064612],
            [25.4271531, -33.8051418],
            [25.4284191, -33.7995586]
        ]]
    },
    {
        id: 'z-nmblp-b',
        name: 'Nelson Mandela Bay Logistics Park',
        color: 'zone-14-color',
        colorHEX: 'rgba(90, 89, 91, 0.5)',
        company: [{
            id: "z-nmblp-b-0001",
            name: "Available land",
            logo: "nmblp-logo.png",
            description: '<strong>Available land</strong>: 837 ha<br/>For investors who wish to take advantage of opportunities in the NMBLP Precinct B, kindly contact;<br/><br/>Mr Mfundo Piti<br/>Office: 041 403 0628<br/>Email: <a href="mailto:mfundo.piti@coega.co.za">mfundo.piti@coega.co.za</a>',
            panoramic: "",
            video: "",
            coordinates: [25.418092, -33.812528],
            sector: "",
            country: "",
            investment: "",
            base: "million",
            products: ""
        }],
        center: [25.418092, -33.812528],
        coordinates: [[
            [25.428436, -33.7992871],
            [25.4205531, -33.7995617],
            [25.4173881, -33.8003195],
            [25.4172259, -33.8048695],
            [25.4169630, -33.8051147],
            [25.4163247, -33.8047358],
            [25.4147441, -33.8057805],
            [25.4142236, -33.8075954],
            [25.4113429, -33.8082908],
            [25.4105510, -33.8094095],
            [25.4108529, -33.8103098],
            [25.4113464, -33.8101761],
            [25.4109387, -33.8118401],
            [25.4125439, -33.8149705],
            [25.4154510, -33.8199149],
            [25.4221914, -33.8111391],
            [25.4220116, -33.8111011],
            [25.4211104, -33.8099422],
            [25.4249049, -33.8087124],
            [25.4266139, -33.8069983],
            [25.4234328, -33.8067242],
            [25.4233309, -33.807785],
            [25.4211288, -33.8076156],
            [25.4211771, -33.8070139],
            [25.4195115, -33.8068713],
            [25.4196322, -33.8055719],
            [25.4191574, -33.8055385],
            [25.4192888, -33.8040007],
            [25.4198280, -33.8036128],
            [25.4197716, -33.8034323],
            [25.4189509, -33.8027258],
            [25.4185378, -33.8021285],
            [25.4211664, -33.7999197],
            [25.4284191, -33.7995586],
            [25.428436, -33.7992871]
        ]]
    }
];
