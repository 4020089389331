import $ from 'jquery';
import 'bootstrap';
import './global-data';
import logos from '../../res/images/logos/*.png';
import icons from '../../res/images/icons/*.png';
import actions from '../../res/images/action/*.png';
import regularLogos from '../../res/images/regularLogos/*.png';
import flags from '../../res/images/flags/*.png';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import MultiPoint from 'ol/geom/MultiPoint';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle,Fill, Stroke, Style, Icon, Text} from 'ol/style';
import {transformExtent, fromLonLat} from 'ol/proj';
import Overlay from 'ol/Overlay';
import {getVectorContext} from 'ol/render';
import {easeOut} from 'ol/easing';
import {unByKey} from 'ol/Observable';
import {defaults as defaultControls, Attribution} from 'ol/control';
import '../scripts/BounceEffect.js';

import {getArea, getLength} from 'ol/sphere';
import {LineString, Polygon} from 'ol/geom';
import Draw from 'ol/interaction/Draw';

window.logos = logos;
window.icons = icons;
window.actions = actions;
window.regularLogos = regularLogos;
window.flags = flags;
window.basePath = 'https://coegamap.rrzdev.co.za';
//window.basePath = 'https://coega.co.za/map/';

window.styles = [
    'Aerial',
    'AerialWithLabelsOnDemand',
    'RoadOnDemand',
    'CanvasDark',
    'OrdnanceSurvey'
  ];
window.layers = [];
window.coegaCenter = [25.673529, -33.760155];
window.coegaDefaultZoom = 13;
window.coegaMinZoom = 11;
window.coegaMaxZoom = 19;

window.vectorStyles = [];
window.geojsonObject = {};
window.source = null;
window.companyFeatures = [];
window.vectorLayer = null;
window.drawSource = null;
window.zoneoffset = 1;
window.pinoffset = 2;
window.navbarOpen = false;

var mapView;

window.isDrawing = false;
window.draw = null; // global so we can remove it later

$(function(){
    autoPlayYouTubeModal();
    toggleNavbar();
    if(document.body.clientWidth < 992) {
        window.coegaDefaultZoom -= 1;
    }
    $('#corporateVideo').on('hidden.bs.modal', function () {
        $('#corporateVideo iframe').removeAttr('src');
    });

    $(document).find('.navbar-toggler').on('click', function(){
        toggleNavbar();
    });

    $("#zoneInfoHolder").addClass('zone-info-inactive');
    $("#zoneMediaHolder").addClass('zone-media-inactive');

    $('.all-click').on('click', function(e){
        $("#zoneInfoHolder").removeClass('zone-info-active');
        $("#zoneInfoHolder").addClass('zone-info-inactive');
        $("#zoneMediaHolder").removeClass('zone-media-active');
        $("#zoneMediaHolder").addClass('zone-media-inactive');
        mapView.setZoom(coegaDefaultZoom);
        mapView.setCenter(coegaCenter);
        toggleNavbar();
        showAllZoneOverlay();
    });

    $('.zone-click').on('click', function(e){
        var zoneID = $(this).data('id');
        zoneClick(zoneID);
    });

    $('.contact-us').on('click', function(e){
        showContactUsModal();
    });

    $(document).on('click','.view-details-click',function(e){
        var descID = $(this).data('id');
        var thisButton  = $(this);
        if (thisButton.html() == 'view more') {
            thisButton.html('hide info');
            thisButton.addClass('hide-info');
        }
        else{
            thisButton.html('view more');
            thisButton.removeClass('hide-info');
        }
        toggleDetails(descID);
    });

    $(document).on('click','.site-media-click',function(e){
        var media = $(this).data('media');
        $("#zoneMedia").attr('src',media);
        $("#zoneMediaHolder").removeClass("zone-media-inactive");
        $("#zoneMediaHolder").addClass("zone-media-active");   
        
        if(document.body.clientWidth < 992) {
            var element = document.getElementById("zoneMediaHolder");
            element.scrollIntoView({behavior: "smooth"});
        }
    });
    $(document).on('click','.hide-media-click',function(e){
        $("#zoneMediaHolder").removeClass("zone-media-active");
        $("#zoneMediaHolder").addClass("zone-media-inactive");
    });

    $(document).on('click', '.comp-info,.modal-control', function(){
        var compID = $(this).attr('data-id');
        var type = $(this).data('type');
        showCompanyModal(compID, type)
    })
    $(document).on('click', '.comp-img', function(){
        var compID = $(this).attr('data-id');
        centerOnComp(compID);
    })
   initMap();
   if(document.body.clientWidth < 992) {
       showAllZoneOverlay();
   }

   $(document).on('click','#measureBtn', function(){
       var thisBtn = $(this);
       thisBtn.toggleClass('active');
       var clearAll = $('#clearAllMeasure');

       if(thisBtn.hasClass('active')){
           isDrawing =  true;
           clearAll.show(400);
           thisBtn.attr('title', 'Stop measuring');

           map.on('pointermove', pointerMoveHandler);

           map.getViewport().addEventListener('mouseout', function() {
               helpTooltipElement.classList.add('hidden');
            });
            
            window.typeSelect = {
                value:'length',
                options: {
                    length: 'Length (LineString)',
                    area: 'Area (Polygon)'
                }
            }
            //document.getElementById('type');
            /**
             * Let user change the geometry type.
             */

            addInteraction();
           map.addInteraction(draw);
           createMeasureTooltip();
           createHelpTooltip();
       }else{
           isDrawing = false;
           clearAll.hide(400);
           thisBtn.attr('title', 'Measure distance');
           map.un('pointermove', pointerMoveHandler);
           helpTooltipElement.classList.add('hidden');
           map.removeInteraction(draw);
           removeInteraction();
           removeMeasureTooltip();
       }
   });
});

$(document).on('click', "#clearAllMeasure", function(){
    drawSource.clear();
    removeAllMeasureTooltip();
})

$(document).on('click', "#measureInfo", function(){
    var modal = $('#measureInfoModal');
    modal.modal();
})
 
String.prototype.replaceAll = function(searchValue, replaceValue){
    if(this) return this.split(searchValue).join(replaceValue);
}

function toggleNavbar(){
    if(navbarOpen){
        $(document).find('.side-bar').addClass('open');
    } else{
        $(document).find('.side-bar').removeClass('open');
    }
    navbarOpen = !navbarOpen;
}

function zoneClick(zoneID){
    $("#zoneInfoHolder").removeClass('zone-info-inactive');
    $("#zoneInfoHolder").addClass('zone-info-active');
    $("#zoneMediaHolder").removeClass("zone-media-active");
    $("#zoneMediaHolder").addClass("zone-media-inactive");
    getZoneData(zoneID);
    showZoneOverlay(zoneID, true);
    
    if(document.body.clientWidth < 992) {
        toggleNavbar();

        var element = document.getElementById("zoneInfoHolder");
        element.scrollIntoView({behavior: "smooth"});
    }
}

function autoPlayYouTubeModal(){
    $("#tourVidBtn").click(function() {
      var theModal = $(this).data( "target" ),
      videoSRC = $(this).attr( "data-thevideo" ), 
      videoSRCauto = videoSRC+"?autoplay=1" ;
      $(theModal+' iframe').attr('src', videoSRCauto);
      $(theModal+' button.close').click(function () {
          $(theModal+' iframe').attr('src', videoSRC);
      });   
    });
    
    $("#corpVidBtn").click(function() {
        var theModal = $(this).data( "target" ),
        videoSRC = $(this).attr( "data-thevideo" ), 
        videoSRCauto = videoSRC+"?autoplay=1" ;
        $(theModal+' iframe').attr('src', videoSRCauto);
        $(theModal+' button.close').click(function () {
            $(theModal+' iframe').attr('src', videoSRC);
        });   
      });
  }

function initMap() {
    var i, ii, j, jj, compFeat;
    var attribution = new Attribution({
	  collapsible: false
	});
	var ngiLayer = new TileLayer({
			visible:true,
            source: new XYZ({
                //hidpi: false,
                //wrapX: false,
				
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            }),
			tileloaderror:function(){}
        });
		layers.push(ngiLayer);
    /*layers.push(new TileLayer({
        visible: true,
        preload: 1,
        source: ngiLayer,
		new BingMaps({
            key: 'Ai2KBsaP5FVnzY-aGAtn9tU5uSE_0n1mK2N7EEUHndpc5OXkwZMvd6NRwGZIUZKH',
            imagerySet: 'Aerial',
            // use maxZoom 19 to see stretched tiles instead of the BingMaps
            // "no photos at this zoom level" tiles
            //maxZoom: coegaMaxZoom
        }),
        tileloaderror: function(){}
        // source: new OSM()    'As1HiMj1PvLPlqc_gtM7AqZfBL8ZL3VrjaS3zIb22Uvb9WKhuJObROC-qUpa81U5'
    }));*/
    
    //var mapExtent = transformExtent([25.598150, -33.826240, 25.766764, -33.685102], 'EPSG:4326', 'EPSG:4326');//'EPSG:3857'

    layers.push(new TileLayer({
        visible: true,
        source: new XYZ({
            //extent: mapExtent,
            url: basePath+"/Tiles/{z}/{x}/{y}.png",
            tilePixelRatio: 1.00000000,
            minZoom: coegaMinZoom,
            maxZoom: coegaMaxZoom
        }),
		tileloaderror:function(){}
    }));
    
    drawSource = new VectorSource();
    layers.length 
    layers.push(new VectorLayer({
        source: drawSource,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
                color: '#123073',
                width: 2
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                color: '#123073'
                })
            })
        })
    }));
    zoneoffset = 3;
    

    var textAlign = 'middle';
    for (i = 0, ii = zoneData.length; i < ii; ++i) {
        vectorStyles = [];
        vectorStyles.push(
            new Style({
                stroke: new Stroke({
                    color: zoneData[i].colorHEX,
                    width: 1
                }),
                fill: new Fill({
                    color: zoneData[i].colorHEX,
                }),
                text: new Text({
                    textAlign: textAlign,
                    textBaseline:i==3?'bottom':textAlign,
                    font: 'bold 12px Arial',
                    text: stringDivider(zoneData[i].name, 10, '\n'),
                    fill: new Fill({color: '#232f55'}),
                    stroke: new Stroke({color: '#ffffff', width: 5}),
                    offsetX: 0,
                    offsetY: 0,
                    placement: 'point',
                    maxAngle: '0.7853981633974483',
                    overflow: 'false',
                    rotation: '0'
                })
            })
        );

        geojsonObject = {
            'type': 'FeatureCollection',
            'crs': {
                'type': 'name',
                'properties': {
                    'name': 'EPSG:3857'
                }
            },
            'features': [{
                'type': 'Feature',
                "properties": {
                    "name": zoneData[i].name,
                    "zone": zoneData[i].id
                },
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': zoneData[i].coordinates
                }
            }]
        };

        companyFeatures = (new GeoJSON()).readFeatures(geojsonObject);
        

        source = new VectorSource({
            features: companyFeatures
        });

        vectorLayer = new VectorLayer({
            visible: true,
            source: source,
            style: vectorStyles
        });
        layers.push(vectorLayer);
    }

    pinoffset = layers.length

    for (i = 0, ii = zoneData.length; i < ii; ++i) {
        companyFeatures = [];
        if(!!zoneData[i].company && Array.isArray(zoneData[i].company) && zoneData[i].company.length > 0){
            for (j = 0, jj = zoneData[i].company.length; j < jj; ++j) {
                if(!!zoneData[i].company[j].coordinates && (!zoneData[i].company[j].pinMobileOnly) && Array.isArray(zoneData[i].company[j].coordinates) && zoneData[i].company[j].coordinates.length==2){
                    compFeat = new Feature({
                        geometry: new Point(zoneData[i].company[j].coordinates),
                        name: zoneData[i].company[j].name,
                        company: zoneData[i].company[j].id
                      });
                    compFeat.setStyle(new Style({
                        image: new Icon({
                          crossOrigin: 'anonymous',
                          src: icons[zoneData[i].company[j].logo.replace('.png','')]        //parcel builds images with random sufix, icons [] has this name
                        })
                    }));
                    companyFeatures.push(compFeat);
                }
            }
        }

        source = new VectorSource({
            features: companyFeatures
        });

        vectorLayer = new VectorLayer({
            visible: false,
            source: source,
            style: vectorStyles
        });
        layers.push(vectorLayer);
    }

    mapView = new View({
        projection: 'EPSG:4326',
        center: coegaCenter,
        zoom: coegaDefaultZoom
    })
    window.map = new Map({
        layers: layers,
		controls:defaultControls({attribution: false}).extend([attribution]),
        target: 'map-layer',
        view: mapView
    });

    var element = document.getElementById('popup');

    var popup = new Overlay({
    element: element,
    positioning: 'bottom-center',
    stopEvent: false,
    offset: [0, -30]
    });
    map.addOverlay(popup);

    // display popup on click
    map.on('click', function(evt) {
        $(element).popover('dispose');
        var feature = map.forEachFeatureAtPixel(evt.pixel,
            function(feature) {
            return feature;
        });
        if (!!feature && !!feature.get('name')) {

            var coID = feature.get('company');
            var zoneID = feature.get('zone');
            if(!!coID){
                var zoneID = coID.substr(0,coID.indexOf('-c'));
                showCompanyModal(coID,'info');
            }
            else if(!!zoneID){
                var isMobile = document.body.clientWidth < 992;
                if(isMobile){
                    var comp = getCompanyById(zoneID+'-c-0001');
                    if(!!comp.pinMobileOnly)
                    {
                        showCompanyModal(comp.id,'info');
                    }
                    else{
                        navbarOpen = !navbarOpen;
                        $('#v-pills-'+zoneID).click();
                    }
                }
            }
        } else {
            $(element).popover('dispose');
        }
    });

    // change mouse cursor when over marker
    map.on('pointermove', function(e) {
        if (e.dragging) {
            $(element).popover('dispose');
            return;
        }
        var pixel = map.getEventPixel(e.originalEvent);
        var hit = map.hasFeatureAtPixel(pixel);
        if(!!map && !!map.getTarget() && map.getTarget().style){
            map.getTarget().style.cursor = hit ? 'pointer' : '';
        }
    });
}


function getZoneData(zone){
    $("#zoneInfoHolder").html('');
    for(var x = 0; x < zoneData.length; x++){
        if(zoneData[x].id == zone){
            showZoneInfo(zoneData[x]);
        }
    }
}

function showZoneOverlay(zone, showPin){
    for (var i = 0, j = 0, ii = zoneData.length; i < ii;++i, ++j) {
        if (zoneData[i].id === zone) {
            layers[i + zoneoffset].setVisible(true);
            if(showPin){
                layers[i + pinoffset].setVisible(true);
            }
            else{
                layers[i + pinoffset].setVisible(false);
            }
            if(!!zoneData[i].center && Array.isArray(zoneData[i].center) && zoneData[i].center.length>0){
                mapView.animate({
                    center: zoneData[i].center,
                    zoom: coegaDefaultZoom+2,
                    duration: 500
                });
            }
        } else {
            layers[i + zoneoffset].setVisible(false);
            layers[i + pinoffset].setVisible(false);
        }
    }
}

function showAllZoneOverlay(){
    for (var i = 0, ii = pinoffset - (zoneoffset + 1); i < ii; ++i) {
        layers[i + zoneoffset].setVisible(true);
        layers[i + pinoffset].setVisible(true);
    }
}

function showZoneInfo(data){

    var zoneID;
    var zone;
    
        zoneID = data.id.substr(-2) * 1;

        if(isNaN(zoneID)){
            zone = 'PRECINCT ' + data.id.substr(-1).toUpperCase();
        } else {
            zone = 'ZONE '+ zoneID;
        }
    var companiesHtml = '<div class="row"><div class="col-12 zone-info-header">' + data.name +' <small>'+ zone+'</small></div></div>';
    var company;
    for(var x = 0; x < data.company.length; x++) {
        company = data.company[x];
        if(company.id==='z-nmblp-b-vw'){
            companiesHtml += '<div class="row"><div class="col-12 company-info-container font-weight-bold">Within 3km of NMBLP</div></div>';
        }
        companiesHtml = companiesHtml + 
                        '<div class="company-info-container" data-id="'+company.id+'">' +
                            '<img class="comp-img" data-id="'+company.id+'" src="'+logos[company.logo.replace('.png','')]+'"/>' +
                            '<div class="row">'+
                                '<div class="col-12 text-center pb-2"><strong>Company</strong> : '+company.name+'</div>'+
                            '</div>'+
                            '<div class="row  justify-content-center">'+
                                '<a class="btn comp-btn comp-info nav-control" title="Company/Location info" data-id="'+company.id+'" data-type="info">'+
                                '<img src="'+actions['info']+'" class="img-fluid"/></a>'+
                                (!!company.panoramic?'<a class="btn comp-btn comp-info nav-control" title="Panoramic view" data-id="'+company.id+'" data-type="panoramic">'+
                                '<img src="'+actions['panoramic']+'" class="img-fluid"/></a>':'')+
                                (!!company.gallery?'<a class="btn comp-btn comp-info nav-control" title="Gallery" data-id="'+company.id+'" data-type="gallery">'+
                                '<img src="'+actions['play']+'" class="img-fluid"/></a>':'')+
                                (!!company.video?'<a class="btn comp-btn comp-info nav-control" title="Timelapse" data-id="'+company.id+'" data-type="timelapse">'+
                                '<img src="'+actions['time-lapse']+'" class="img-fluid"/></a>':'')+
                            '</div>'+
                        '</div>';
    }
    $("#zoneInfoHolder").html(companiesHtml);
}

function showContactUsModal(){
    var modal = $('#contactUsModal');
    modal.modal();
}

function toggleDetails(descID){
    var descriptionElement = document.getElementById(descID);
    if(descriptionElement.classList.contains('inactive')){
        descriptionElement.classList.remove('inactive');
        descriptionElement.classList.add('active');
    }
    else{
        descriptionElement.classList.remove('active');
        descriptionElement.classList.add('inactive');
    }
}

function showCompanyModal(compID, type){
    var modal = $('#zoneMediaModal');
    modal.find('.container').hide();
    modal.find('.'+type).show();

    var thisComp = getCompanyById(compID);
    if(!!thisComp){
        modal.find('.modal-control').attr('data-id',compID);
        modal.modal();

        modal.find('.modal-control').addClass('inactive');
        if(!thisComp.panoramic) {
            modal.find('.modal-control[data-type="panoramic"]').hide();
        } else {  modal.find('.modal-control[data-type="panoramic"]').show();}

        
        if(!thisComp.video) {
            modal.find('.modal-control[data-type="timelapse"]').hide();
        } else {  modal.find('.modal-control[data-type="timelapse"]').show();}

        modal.find('.modal-control[data-type="info"]').show();
        switch(type){
            case 'info':
                showCompInfo(thisComp, modal);
                modal.find('.modal-control[data-type="info"]').removeClass('inactive');
            break;
            case 'gallery':

                break;
            case 'timelapse':
                showCompVideo(thisComp, modal)
                modal.find('.modal-control[data-type="timelapse"]').removeClass('inactive');
                break;
            case 'panoramic':
                showCompPanormic(thisComp, modal)
                modal.find('.modal-control[data-type="panoramic"]').removeClass('inactive');
                break;
            default:
                break;
        }
    }

}

function getCompanyById(compID) {
    var i,j;
    var data, company;
    for(i = 0; i < zoneData.length; i++) {
        data = zoneData[i];
        for(j = 0; j < data.company.length; j++) {
            company = data.company[j];
            if(company.id == compID)
            return company;
        }
    }
    return null;
}

function showCompPanormic(company, cModal) {
    var currentUrl = $("#panoramicMedia").attr('src');
    if(company.panoramic !== currentUrl) {
        $("#panoramicMedia").attr('src',basePath+company.panoramic);
    }

    cModal.find('.panoramic-container').show();
}

function showCompVideo(company, cModal) {
    var currentUrl = $("#videoMedia").attr('src');
    if(company.video !== currentUrl) {
        $("#videoMedia").attr('src',basePath+company.video);
    }

    cModal.find('.video-container').show();
}

function showCompInfo(company, cModal) {
    var logoUrl = regularLogos[company.logo.replace('.png','')];
    var currentUrl = cModal.find('#comp-logo').attr('src');
    if(logoUrl !== currentUrl) {
        cModal.find('#comp-logo').attr('src', logoUrl);
    }
    
    var country = company.flag?company.flag.replace('.png',''):'none';
    var countryUrl = flags[country];

    var flagDiv = cModal.find('.country-back');
    flagDiv.removeClass();

    if(countryUrl){
        flagDiv.addClass('col-12 col-md-3 info-block color country-back');
        flagDiv.addClass(country);
    } else{
        flagDiv.addClass('col-12 col-md-3 info-block color country-back');
    }
    
    cModal.find('#comp-name').html(company.name);
    cModal.find('.comp-desc').html(company.description);

    if(!company.investment && !company.sector && !company.country){
        cModal.find('.info-blocks').hide();
    }
    else if(!company.investment){
        cModal.find('.invest-back').hide();
        cModal.find('.sector-back').removeClass('col-md-3').addClass('col-md-4');
        cModal.find('.country-back').removeClass('col-md-3').addClass('col-md-4');

        cModal.find('.info-blocks').show();
    }
    else{
        cModal.find('.invest-back').show();
        cModal.find('.sector-back').removeClass('col-md-4').addClass('col-md-3');
        cModal.find('.country-back').removeClass('col-md-4').addClass('col-md-3');
        cModal.find('.info-blocks').show();
    }
    cModal.find('#comp-invest-amount').html(company.investment);
    cModal.find('#comp-invest-base').html(company.base);
    cModal.find('#comp-sector').html(company.sector);
    cModal.find('#comp-country').html(company.country);
    cModal.find('#comp-products').html(company.products);

    cModal.find('.info-container').show();
}

function centerOnComp(compID){
    var thisComp = getCompanyById(compID);
    if(!!thisComp && !!thisComp.coordinates){
        mapView.animate({
            center: thisComp.coordinates,
            duration: 500
        });

        var vectorSource, theseFeatures;
        var features = [];
        for(var i = 0; i < layers.length; i++){
            if(layers[i] instanceof VectorLayer){
                theseFeatures = layers[i].getSource().getFeatures();
                if(Array.isArray(theseFeatures)){
                    if(theseFeatures.length > 0){
                        for(var j = 0; j < theseFeatures.length; j++){
                            theseFeatures[j].layerIndex = i;
                        }
                        features = features.concat(theseFeatures);
                    }
                }
                else{
                    theseFeatures.layerIndex = i;
                    features.push(theseFeatures);
                }
            }
        }
        
        var property = 'company';
        var found;
        for (var i = 0, ii = features.length; i < ii; i++) {
            if (features[i].get(property) === compID) {
                found = features[i];
                // flash(found);
                break;
            }
        }
    }
}

var duration = 3000;
function flash(feature) {
  var start = new Date().getTime();
  var listenerKey = layers[feature.layerIndex].on('postrender', animate);

  function animate(event) {
    var vectorContext = getVectorContext(event);
    var frameState = event.frameState;
    var flashGeom = feature.getGeometry().clone();
    var elapsed = frameState.time - start;
    var elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    var radius = easeOut(elapsedRatio) * 25 + 5;
    var opacity = easeOut(1 - elapsedRatio);

    var style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, ' + opacity + ')',
          width: 0.25 + opacity
        })
      })
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    if (elapsed > duration) {
      unByKey(listenerKey);
      return;
    }
    // tell OpenLayers to continue postrender animation
    map.render();
  }
}

// http://stackoverflow.com/questions/14484787/wrap-text-in-javascript
function stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
      var p = width;
      while (p > 0 && (str[p] != ' ' && str[p] != '-')) {
        p--;
      }
      if (p > 0) {
        var left;
        if (str.substring(p, p + 1) == '-') {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        var right = str.substring(p + 1);
        return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
      }
    }
    return str;
  }
  /*function stringDivider(str, width, spaceReplacer){
      str = str.replaceAll(' ',spaceReplacer);
      return str;
  }*/

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
var sketch;


/**
 * The help tooltip element.
 * @type {HTMLElement}
 */
var helpTooltipElement;


/**
 * Overlay to show the help messages.
 * @type {Overlay}
 */
var helpTooltip;


/**
 * The measure tooltip element.
 * @type {HTMLElement}
 */
var measureTooltipElement;


/**
 * Overlay to show the measurement.
 * @type {Overlay}
 */
var measureTooltip;


/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
var continuePolygonMsg = 'Click to continue drawing the polygon, Shift + Click to free-hand draw';


/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
var continueLineMsg = 'Click to continue drawing the line';


/**
 * Handle pointer move.
 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
 */
var pointerMoveHandler = function(evt) {
  if (evt.dragging) {
    return;
  }
  /** @type {string} */
  var helpMsg = 'Click to start drawing';

  if (sketch) {
    var geom = sketch.getGeometry();
    if (geom instanceof Polygon) {
      helpMsg = continuePolygonMsg;
    } else if (geom instanceof LineString) {
      helpMsg = continueLineMsg;
    }
  }

  helpTooltipElement.innerHTML = helpMsg;
  helpTooltip.setPosition(evt.coordinate);

  helpTooltipElement.classList.remove('hidden');
};


/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
var formatLength = function(line) {
  var length = getLength(line) * 100;
  var output;
  if (length > 1) {
    output = (Math.round(length * 100) / 100) +
        ' ' + 'km';
  } else {
    output = Math.round(length * 100) +
        ' ' + 'm';
  }
  return output;
};


/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
var formatArea = function(polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = (Math.round(area / 1000000 * 100) / 100) +
        ' ' + 'km<sup>2</sup>';
  } else {
    output = (Math.round(area * 100) / 100) +
        ' ' + 'm<sup>2</sup>';
  }
  return output;
};

function addInteraction() {
  var type = (typeSelect.value == 'area' ? 'Polygon' : 'LineString');
  draw = new Draw({
    source: drawSource,
    type: type,
    style: new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        })
      })
    })
  });

  var listener;
  draw.on('drawstart',
    function(evt) {
      // set sketch
      sketch = evt.feature;

      /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
      var tooltipCoord = evt.coordinate;

      listener = sketch.getGeometry().on('change', function(evt) {
        var geom = evt.target;
        var output;
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        measureTooltipElement.innerHTML = output;
        measureTooltip.setPosition(tooltipCoord);
      });
    });

  draw.on('drawend',
    function() {
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      measureTooltip.setOffset([0, -7]);
      // unset sketch
      sketch = null;
      createMeasureTooltip();
      unByKey(listener);
    });
}


function removeInteraction() {
    draw.un('drawstart');
    draw.un('drawend');
  }


/**
 * Creates a new help tooltip
 */
function createHelpTooltip() {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement('div');
  helpTooltipElement.className = 'ol-tooltip hidden';
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left'
  });
  map.addOverlay(helpTooltip);
}


/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip() {
    // unset tooltip so that a new one can be created
    //removeMeasureTooltip();

  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center'
  });
  map.addOverlay(measureTooltip);
}
function removeMeasureTooltip() {
    if (measureTooltipElement) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      measureTooltipElement = null;
    }
  }
  function removeAllMeasureTooltip() {
      var measureTooltipElements = document.getElementsByClassName('ol-tooltip-static')
      for(var i = measureTooltipElements.length - 1; i >= 0; i--) {
        measureTooltipElements[i].parentNode.removeChild(measureTooltipElements[i]);
      }
    }

